import React, { useState, useEffect } from 'react';
import PageTitle from '../components/pagetitle';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  Box,
  alertVariants,
  Alert,
  Button,
  Input,
  AddIcon,
  RemoveIcon,Text
} from '@pancakeswap/uikit';
import CopyAddress from '../utils/CopyAddress';

import {
  getContractHandler,
  _getAddress,
  _getProvider,
  useWalletHook,
} from '../utils/ethereum';
import useToast from '../utils/useToast';
import { useTranslation } from '../utils/contexts/Localization';
import { BigNumber, Contract, providers, utils } from 'ethers';
import { Constants, ImgUrl, IsTest, WasaiUrl } from '../utils/Config';
import handleUserAddress from '../utils/Http';


 

function Project2(props) {
  const [dataTab] = useState([
    {
      id: 1,
      title: 'Market',
    },
    {
      id: 2,
      title: 'My List',
    },
  ]);

  const { toastSuccess, toastError } = useToast();

  const [selectedIndex, setSelectIndex] = useState(0);
  console.log('selectedIndex', selectedIndex);

  const [start, setStart] = useState(0);
  const [total, setTotal] = useState(0);
  const [nowpage, setNowPage] = useState(1);
  const { t } = useTranslation();
  const [nowpageV, setNowPageV] = useState();

  const [queryTotal, setQueryTotal] = useState(0);

  const [nftList, setNftList] = useState([]);

  const { wallet, chain } = useWalletHook();
  const [nextPageTokens, setNextPageTokens] = useState();

  const [index2, setIndex2] = useState(1);

  const [raws, setRaws] = useState([]);

  const [selected, setSelected] = useState([]);


  const [queryAccount, setQueryAccount] = useState();


  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState("");
  const [detailToken, setDetailToken] = useState("");

  //查询观察列表
  const queryTokens = async (nextPage, account) => {

    if(nftList.length>90){
      toastError(t('只可以查看100以内藏品'));
      return;
    }
    setQueryAccount(account)
    //我的列表
    let nextPageToken = '1';
    if (nextPage) {
      nextPageToken = nextPage;
    }

    if (!account) {
      toastError(t('Please connect wallet'));
      return;
    }
    const response = await axios.get(`${ImgUrl}/query/${account}/${nextPageToken}`);
    console.log('response.data.data', response.data.data);
    if (response.data.data) {
      const filteredAssets = response.data.data.assets.filter(asset => asset.tokenId >= 1000);

      if (nftList.length > 0) {
        setNftList([...nftList, ...filteredAssets]);
      } else {
        setNftList(filteredAssets);
      }
      if (response.data.data.nextPageToken && response.data.data.nextPageToken !== nextPageTokens) {
        setNextPageTokens(response.data.data.nextPageToken);
        setIndex2(index2 + 1);
      }
    }
  };

  const querySharList = async () => {
    const raws = await handleUserAddress('list', wallet);
    console.log('resut', raws);
    if (raws && raws.result) {
      setRaws(raws.result);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const otherAddress = localStorage.getItem('other_address');
      const otherToken = localStorage.getItem('other_token');

      console.log('otherAddress', otherAddress, otherToken);

      if (wallet && chain) {
        if (selectedIndex === 0) {
          const account = await _getAddress();
          queryTokens('1', account);
        } else {
          querySharList();
        }
      } else if (otherAddress && otherToken) {

        //检查数据有效性
        if (selectedIndex === 0) {


          handleUserAddress("read", otherAddress, "", otherToken, "").then((res) => {

            if (res.result && res.result[0]&&res.result[0].selected) {
              const newList = res.result[0].selected.split(',');

              console.log("res.selected", res.result[0].selected)
              setSelected(newList)
            }

            if (res.result &&res.result[0]&& res.result[0].address) {
              //初始化
              
              queryTokens('1', otherAddress);
            }
            
            console.log("resss", res)
          })


 

        }
 

      }
    };

    fetchData();
  }, [selectedIndex]);

  useEffect(() => {
    const fetchTokens = async () => {
      if (wallet && chain) {
        queryTokens("1",wallet);
      }
    };

    fetchTokens();
  }, [wallet, chain]);

  const selectNFT = (select) => {
    console.log('select333', select, selected.includes(select));

    if (!selected.includes(select)) {
      setSelected([...selected, select]);
    } else {
      removeItem(select);
    }
  };

  const removeItem = (itemToRemove) => {
    // 找到要移除对象的索引
    const index = selected.findIndex((item) => item === itemToRemove);
    // 如果找到了，就移除它
    if (index !== -1) {
      const newSelected = [...selected];
      newSelected.splice(index, 1);
      setSelected(newSelected);
    }
  };

  const showMore=()=>{

    const otherAddress = localStorage.getItem('other_address');
    const otherToken = localStorage.getItem('other_token');

    console.log('otherAddress', otherAddress, otherToken);

    if (wallet && chain) {
       queryTokens(nextPageTokens, wallet);
    }else if (otherAddress){
      handleUserAddress("read", otherAddress, "", otherToken, "").then((res) => {

        if (res.result && res.result[0]&&res.result[0].selected) {
          const newList = res.result[0].selected.split(',');

          console.log("res.selected", res.result[0].selected)
          setSelected(newList)
        }

        if (res.result &&res.result[0]&& res.result[0].address) {
          //初始化
          
          queryTokens(nextPageTokens, otherAddress);
        }
        
        console.log("resss", res)
      })
    }

  }

  console.log('total', total);

  const saveSelect = async () => {
    const otherAddress = localStorage.getItem('other_address');
    const otherToken = localStorage.getItem('other_token');

    const commaSeparatedString = selected.join(',');

    handleUserAddress("update", otherAddress, "", otherToken, commaSeparatedString).then((res) => {

      console.log("update res", res)

      toastSuccess("保存成功")
    })
  }


  const detail =async (item)=>{
    if(item.token==detailToken){
      setDetailToken("")
    }else{
      setDetailToken(item.token)
    }
    
  }

  const sendNFT =async (item)=>{
    
    

    if(item.selected&&item.selected.length<2){
      toastError("没有选择藏品")
      return ;
    }

    try {

      const account = await _getAddress()

      const nftTransfer = await getContractHandler('nftTransfer')
      const WasaiNFT = await getContractHandler('WasaiNFT')
      if (!nftTransfer) return;
      // const att = await WasaiMint.openBox(ids)
      //检查授权
      //如果没有授权，先提示授权

      if (!item.toAddress||(item.toAddress&&item.toAddress.length<42)) {
          toastError("请输入正确地址！"+item.toAddress)
          return ;
      }

      const is = await WasaiNFT.isApprovedForAll(account, Constants.Contract.nftTransfer)
      if (!is) {
          toastError(t("NO Approved"))
          await WasaiNFT.setApprovalForAll(Constants.Contract.nftTransfer, true);
          toastSuccess(t('Approved'))
          return;
      }
      const ids = item.selected.split(",")
      await nftTransfer.transferNFT(item.toAddress, ids)
      toastSuccess('Successfully submit nft!')

  } catch (e) {

      toastError(e?.message)
      console.error(e?.message)
  }

  }

  return (
    <div className="inner-page">
      <PageTitle />
      <Text style={{marginBottom:"10px",marginLeft:"20px"}} color="textSubtle"> {queryAccount  ?""+ queryAccount.substring(0,5)+"..."+queryAccount.substring(35,42):""}</Text>
      <section className="tf-section project">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="flat-tabs"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Tabs
                  selectedIndex={selectedIndex}
                  onSelect={(index) => {
                    setStart(0);
                    console.log('index', index);
                    setSelectIndex(index);
                  }}
                >
                  <div className="wrapper_menutab">
                    <TabList className="menu-tab">
                      {dataTab.map((idx, ide) => (
                        <Tab className="fs-14 h6" key={idx.id} tabIndex={ide}>
                          {t(idx.title)}
                        </Tab>
                      ))}
                    </TabList>

                    <div
                      className="seclect-box"
                      data-aos="fade-in"
                      data-aos-duration="800"
                    >
                      <div className="dropdown selector-drop" id="category">
                        <Button className="btn-selector">
                          {selectedIndex === 0 && <span className="boder" onClick={() => { saveSelect() }}>{t('Save')}</span>}
                          {selectedIndex === 1 && (
                            <span
                              className="boder"
                              onClick={() => {
                                handleUserAddress('create', wallet).then(() => {
                                  querySharList();
                                });
                              }}
                            >
                              {t('Create')}
                            </span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <TabPanel className="content-tab mt40">
                    <>
                   
                      <div className="content-inner project-box-style6_wrapper">
                        {nftList.map((item, itemid) => (
                          <div
                            key={itemid}
                            className="content-inner project-box-style4"
                          >
                            <div className="content">
                              <div className="image" style={{ width: '100%' }}>
                                <img src={"https://metainfo231.wsxq.space/assets/thumbnails/thumb-"+item?.tokenId+".png"} alt=""   onClick={() => {
                                  setPhotoIndex(item?.imageUrl);
                                  setIsOpen(true);
                                }} />
                              </div>

                              <ul style={{ width: '100%' }}>
                                <li>
                                  <p className="text"> ID</p>
                                  <p className="price">{item?.tokenId}</p>


                                  {!selected.includes(item?.tokenId) && (
                                    <div
                                      className="price3"
                                      onClick={() => {
                                        selectNFT(item?.tokenId);
                                      }}
                                    >
                                      {t('Select')}
                                    </div>
                                  )}
                                  {selected.includes(item?.tokenId) && (
                                    <div
                                      className="price4"
                                      onClick={() => {
                                        selectNFT(item?.tokenId);
                                      }}
                                    >
                                      {t('Remove')}
                                    </div>
                                  )}
                                </li>

                                 
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-md-12">
                        <div className="wrap-btn justify-content-center">
                          <div className="tf-button style1" onClick={()=>{
showMore()
                          }}>Show more</div>
                        </div>
                      </div>
                    </>
                  </TabPanel>

                  <TabPanel className="content-tab mt40">
                    <>
                      <div className="content-inner project-box-style3_wrapper">
                        {raws.map((item, inx) => (<>
                          <div
                            className="project-box-style3"
                            style={{ padding: '30px 0' }}
                            data-aos="fade-in"
                            data-aos-duration="800"
                            key={inx}
                          >
                            <div className="content">
                              <div className="td td1">
                                <p>{"邀请连接：#"}{inx + 1}</p>
                              </div>
                              <div className="td td2" style={{ width: '320px' }}>
                                <p>
                                  <CopyAddress account={`${WasaiUrl}?address=${item?.address}&token=${item?.token}`} />
                                </p>
                              </div>
                              <div className="td td3" style={{ width: '220px' }}>
                               <p>{"接收者地址："}</p>
                                <Input value={item.toAddress} onChange={(e)=>{
                                  console.log("e.target.value",e.target.value)
                                     // item.toAddress = e.target.value


                                      const raws2 = [...raws];
                                      raws2[inx].toAddress = e.target.value;
                                      setRaws(raws2);
                                    }}/>
                              </div>
                              <div className="td td4">
                                <Button variant='warning'  onDoubleClick={()=>{
                                  handleUserAddress("delete",item.address,"",item.token,"").then(()=>{
                                    querySharList();
                                  })
                                }}>{t('Delete')}</Button>
                              </div>
                              <div className="td td4" onClick={()=>{sendNFT(item)}}>
                                <Button>{t('Send')}</Button>
                              </div>

                              <div className="td td4" onClick={()=>{detail(item)}}>
                                <Button variant={item.selected?"success":"subtle"} >{t('详情')}</Button>
                              </div>
                            </div>

                          </div>


                        {item.token==detailToken&&  <div className="content-inner project-box-style6_wrapper">
                            {item.selected?.split(",").map((item2, itemid2) => (
                              <>{item2&&<div
                                key={itemid2}
                                className="content-inner project-box-style4"
                              >
                                <div className="content">
                                  <div className="image" style={{ width: '100%' }}>

                                 
                                    <img src={"https://metainfo231.wsxq.space/assets/thumbnails/thumb-"+item2+".png"} alt="" />
                                  </div>

                                  <ul style={{ width: '100%' }}>
                                    <li>
                                      <p className="text"> ID</p>
                                      <p className="price">{item2}</p>
                                    </li>

                                  </ul>
                                </div>
                              </div>}</>
                              
                            ))}
                          </div>
                          }
                          
                          </>
                        ))}
                      </div>
                    </>
                  </TabPanel>
                </Tabs>
              </div>

              {isOpen && (

                  <div className="fullscreen-container" onClick={() => setIsOpen(false)}>
                  <img src={photoIndex} alt="fullscreen" className="fullscreen-image" />
                </div>
                )}
            </div>
            <div className="col-md-12"></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Project2;
